import { Controller } from '@hotwired/stimulus';

import Croppie from 'croppie';
import 'croppie/croppie.css';

import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  static values = {
    url: String,
    filename: String,
    width: Number,
    height: Number
  }

  static targets = ['input', 'preview']

  connect() {
    super.connect();

    this.photoChanged = false;
    this.uploaded = false;
    this.uploadInFlight = false;

    if (this.urlValue) {
      this.initializeCroppie();
    }
  }

  upload() {
    console.log('upload');
    this.inputTarget.click();
  }

  initializeCroppie() {
    if (this.croppie) {
      this.croppie.bind({ url: this.urlValue })
    } else {
      this.previewTarget.innerHTML = null;
      this.croppie = new Croppie(this.previewTarget, {
        url: this.urlValue,
        enableExif: true,
        viewport: {
          width: this.widthValue,
          height: this.heightValue
        },
        boundary: {
          width: this.widthValue,
          height: this.heightValue
        }
      })
    }
  }

  uploadPhoto() {
    if (this.photoChanged) {
      return new Promise((resolve, reject) => {
        this.croppie.result('blob').then(blob => {
          const url = this.inputTarget.dataset.directUploadUrl;
          blob.name = this.filenameValue;
          const upload = new DirectUpload(blob, url);
          upload.create((error, blob) => {
            if (error) {
              reject(error);
            } else {
              resolve(blob);
            }
          })
        })
      })
    }
  }
  photoSelected(event) {
    const field = event.target;

    if (field.files && field.files[0]) {
      var reader = new FileReader();
      reader.onload = this.imageLoaded.bind(this);
      this.file = field.files[0];
      this.filenameValue = this.file.name;
      this.photoChanged = true;
      reader.readAsDataURL(this.file);
    }
  }

  imageLoaded(event) {
    this.urlValue = event.target.result;
    this.inputTarget.form.addEventListener('submit', this.handleFormSubmit.bind(this));
    this.initializeCroppie();
  }

  handleFormSubmit(event) {
    if (this.photoChanged) {
      if (this.uploadInFlight) {
        event.preventDefault();
        return;
      } else if (this.uploaded) {
        return;
      }
    } else {
      return;
    }

    this.uploadInFlight = true;

    event.preventDefault();
    const form = event.target;

    this.uploadPhoto().then(blob => {
      if (blob && blob.signed_id) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('value', blob.signed_id);
        hiddenField.name = this.inputTarget.name;
        form.appendChild(hiddenField);
      }

      this.uploadInFlight = false;
      this.uploaded = true;
      form.removeEventListener('submit', this.handleFormSubmit.bind(this));
      form.requestSubmit();
    })
  }
}